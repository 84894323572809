<template>
  <v-app>

    <v-app-bar app color="secundary" dark>

      <div class="d-flex align-center">
        <a href="/">
          <v-img alt="Vuetify Logo" class="shrink mr-2" contain src="https://titulos.com.ar/img/titulos_logo_01.svg" transition="scale-transition" width="200" />
        </a>

      </div>

      <v-spacer></v-spacer>

      <v-toolbar-items class="hidden-sm-and-down">

        <v-divider vertical></v-divider>

        <v-btn @click="abreLink('https://multiliveview.com')" text>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon class="mr-3" color="secundary" v-bind="attrs" v-on="on">
                mdi-newspaper
              </v-icon>
            </template>
            <span>Tapas de los diarios más importantes de Argentina</span>
          </v-tooltip> Multi Live View
        </v-btn>

        <v-divider vertical></v-divider>

        <v-btn @click="$router.push('/mas-titulos')" text>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon class="mr-3" color="secundary" v-bind="attrs" v-on="on">
                mdi-subtitles
              </v-icon>
            </template>
            <span>Más títulos de último momento</span>
          </v-tooltip> Más títulos
        </v-btn>

        <v-divider vertical></v-divider>
      </v-toolbar-items>
      <v-switch class="ml-3" @change="toggleDarkTheme()" v-model="$vuetify.theme.dark" inset persistent-hint></v-switch>

      <v-dialog @keydown.esc="cancel" @keydown.enter="buscar()" max-width="350" v-model="dialog">
        <template v-slot:activator="{ on, attrs }">

          <v-btn v-bind="attrs" v-on="on" icon>
            <v-icon>mdi-magnify</v-icon>
          </v-btn>
        </template>
        <template v-slot:default="dialog">
          <v-card>
            <v-toolbar color="primary" dark>Ingrese la palabra a buscar</v-toolbar>

            <v-card-text class="text-center">
              <div class="text-h2 pa-6">
                <v-text-field v-model="text"></v-text-field>
                <v-btn @click="buscar()" depressed color="primary">Buscar</v-btn>
              </div>

            </v-card-text>
            <v-card-actions class="justify-end">
              <v-btn text @click="dialog.value = false">Cerrar</v-btn>
            </v-card-actions>
          </v-card>
        </template>
      </v-dialog>

    </v-app-bar>

    <v-main dark>
      <router-view :key="$route.fullPath" />
    </v-main>

    <v-footer dark padless>
      <v-card class="flex" flat tile>
        <v-card-title class="secundary">

          <v-spacer></v-spacer>

          <v-btn class="mx-4" dark icon>
            <v-icon @click="abreLink('https://www.facebook.com/titulos.com.ar')" size="24px">
              mdi-facebook
            </v-icon>
          </v-btn>

          <v-btn class="mx-4" dark icon>
            <v-icon @click="abreLink('https://twitter.com/ArTitulos')" size="24px">
              mdi-twitter
            </v-icon>
          </v-btn>

          <v-btn class="mx-4" dark icon>
            <v-icon @click="abreLink('https://www.instagram.com/titulos.com.ar')" size="24px">
              mdi-instagram
            </v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text class="py-2 white--text text-center">
          {{ new Date().getFullYear() }} — titulos.com.ar | <strong>Power by <a style="text-decoration: none;" href="https://mediasupport.com.ar">Mediasupport</a></strong>
        </v-card-text>
      </v-card>
    </v-footer>
  </v-app>
</template>


<script>

export default {
metaInfo: {
      // if no subcomponents specify a metaInfo.title, this title will be used
      title: 'Titulares más importantes de Argentina y el mundo minuto a minuto',
      // all titles will be injected into this template
      titleTemplate: '%s - titulos.com.ar',    
      
      

    },
  
  data: () => ({
    temaa: null,
    tema: null,
    text: "",
    dialog: false,
  }),
  mounted() {
    const hasDarkPreference = window.matchMedia(
      "(prefers-color-scheme: dark)"
    ).matches;

    if (localStorage.theme) {
      this.theme = localStorage.theme;
      if (this.theme == "false") {
        this.theme = false;
      } else {
        this.theme = true;
      }
      this.$vuetify.theme.dark = this.theme;
      //console.log(this.theme);
    } else {
      if (hasDarkPreference) {
        localStorage.setItem("theme", (this.$vuetify.theme.dark = true));
        //console.log(this.$vuetify.theme.dark);
      }
    }
  },
  methods: {
    toggleDarkTheme() {
      localStorage.theme = this.$vuetify.theme.dark;
    },
    buscar() {
      this.dialog = false;
      this.$router.push("/buscar/" + this.text);
      this.text = "";
    },
    abreLink(link) {
      // Put the full page URL including the protocol http(s) below
      window.open(link, "_blank");
    },
  },
};
</script>

<style scoped>
.v-toolbar .v-input {
  margin-top: 24px !important;
}
</style>


