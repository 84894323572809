/* eslint-disable no-console */

import { register } from 'register-service-worker'

if (process.env.NODE_ENV === 'production') {
    register(`${process.env.BASE_URL}service-worker.js`, {
        ready() {
            console.log(
                'La App está siendo servida desde el cache por el service worker.\n'
            )
        },
        registered() {
            console.log('El Service worker ha sido registrado.')
        },
        cached() {
            console.log('El contenido ha sido guardado para estar disponible offline.')
        },
        updatefound() {
            console.log('Nuevo contenido está siendo descargado.')
        },
        updated() {
            console.log('Nuevo contenido está disponible; por favor refresque la página.')
        },
        offline() {
            console.log('No se encontró una conexión a internet. La App está corriendo en el modo offline.')
        },
        error(error) {
            console.error('Error mientras se registraba el service worker:', error)
        }
    })
}