import Vue from 'vue'
import App from './App.vue'
import router from './router'
import VueSocialSharing from 'vue-social-sharing'
import vuetify from './plugins/vuetify'
import './assets/css/app.css';
import './registerServiceWorker'
//import Ads from 'vue-google-adsense'
//import VueAnalytics from 'vue-analytics'


/*Vue.use(VueAnalytics, {
    id: 'UA-120924610-1',
    router,
    autoTracking: {
        pageviewTemplate(route) {
            return {
                page: route.path,
                title: document.title,
                location: window.location.href,
            }
        }
    }
})
*/

Vue.use(VueSocialSharing);

/*
Vue.use(require('vue-script2'))
Vue.use(Ads.Adsense)
Vue.use(Ads.InArticleAdsense)
Vue.use(Ads.InFeedAdsense)
*/

Vue.config.productionTip = false

new Vue({
    vuetify,
    router,
    render: h => h(App)
}).$mount('#app')