import Vue from 'vue'
import VueRouter from 'vue-router'
import Meta from 'vue-meta'
Vue.use(Meta)

Vue.use(VueRouter)

const routes = [{
        path: '/',
        name: 'Home',
        component: () =>
            import ( /* webpackChunkName: "Home" */ "../views/Home.vue")
    },
    {
        path: '/tapas-de-los-diarios-de-hoy',
        name: 'diarios',
        props: true,
        component: () =>
            import ( /* webpackChunkName: "diarios" */ "../views/Tapas.vue")


    },
    {
        path: '/mas-titulos',
        name: 'mastitulos',
        props: true,
        component: () =>
            import ( /* webpackChunkName: "mas-titulos" */ "../views/Mas-titulos.vue")


    },
    {
        path: '/general/:catchAll(.*)/:id',
        name: 'titulo',
        props: true,
        component: () =>
            import ( /* webpackChunkName: "titulo" */ "../views/Despliegue.vue")


    },
    {
        path: '/buscar/:palabra',
        name: 'buscar',
        props: true,
        component: () =>
            import ( /* webpackChunkName: "buscar" */ "../views/Buscar.vue")


    },
    {
        path: '/buscar/:palabra/:pagina',
        name: 'buscar_pagina',
        props: true,
        component: () =>
            import ( /* webpackChunkName: "buscar_pagina" */ "../views/Buscar.vue")


    },
    {
        path: '*',
        name: 'Home',
        component: () =>
            import ( /* webpackChunkName: "Home" */ "../views/Home.vue")
    },
    {
        path: '/amp/:catchAll(.*)/:id',
        name: 'titulo',
        props: true,
        component: () =>
            import ( /* webpackChunkName: "amp_redirect" */ "../views/Amp_redirect.vue")


    },



]
const router = new VueRouter({
    mode: 'history',
    routes,
    scrollBehavior() {
        return { x: 0, y: 0 }
    }
})
export default router