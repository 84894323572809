import Vue from 'vue';
import es from 'vuetify/lib/locale/es';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

const opts = {}

export default new Vuetify({
    opts,
    lang: {
        locales: { es },
        current: 'es',
    },
});